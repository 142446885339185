
<template>
  <div class="page-dashboard">
    <div class="row">
      <div class="col-lg-12 card card-custom dashboard-farm-controller">
          <b-container>
            <b-row class="mt-5 mb-5">
              <b-form-row class="form-inline col-xxl-3 col-lg-4 col-sm-12">
                <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.startDate }} </label>
                <b-form-datepicker
                    v-model="startYmd"
                    :locale="locale"
                    class="farm-custom-datepicker"
                    placeholder=""
                    style="width: 200px"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @input="changeDate"
                ></b-form-datepicker>
              </b-form-row>
              <b-form-row class="form-inline col-xxl-3 col-lg-4 col-sm-12">
                <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.endDate }} </label>
                <b-form-datepicker
                    v-model="endYmd"
                    :locale="locale"
                    class="farm-custom-datepicker"
                    placeholder=""
                    style="width: 200px"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @input="changeDate"
                ></b-form-datepicker>
              </b-form-row>
              <b-list-group horizontal>
                <b-list-group-item
                  v-for="(dateRange) in fixedDateRange.dateRanges"
                  button
                  :key="dateRange.key"
                  :active="fixedDateRange.activeDateRange === dateRange.key"
                  @click="onFixedDateRangeChanged(dateRange.key)"
                >
                  {{ dateRange.date }}
                </b-list-group-item>
              </b-list-group>
            </b-row>
          </b-container>
      </div>
      <statistics-report
          :my-farm="myFarm"
          :farm-connects="farmConnects"
          :system-id="currentSystemId"
          :weekly-statistics="weeklyStatistics"
          :weekly-comments="weeklyComments"
          :set-system-id="setSystemId"
          :set-compare-system-id="setCompareSystemId"
          :reset-my-data="resetMyData"
          :reset-compare-data="resetCompareData"
          :start-ymd="startYmd"
          :end-ymd="endYmd"
          farm-type="myfarm"
          :mutate-value="mutateValue"
          @setWeeklyStat="setWeeklyStat"
      ></statistics-report>
      <statistics-report
          :my-farm="compareFarm"
          :farm-connects="compareConnects"
          :system-id="currentCompareSystemId"
          :weekly-statistics="weeklyCompareStatistics"
          :weekly-comments="weeklyCompareComments"
          :set-system-id="setSystemId"
          :set-compare-system-id="setCompareSystemId"
          :reset-my-data="resetMyData"
          :reset-compare-data="resetCompareData"
          :start-ymd="startYmd"
          :end-ymd="endYmd"
          farm-type="comparefarm"
          :mutate-value="mutateValue"
          @setComparetWeeklyStat="setComparetWeeklyStat"
      ></statistics-report>
    </div>
  </div>
</template>
<script>
import {
	getItem,
	getItems,
	lengthCheck,
	collectionsCheck,
  todayToDate,
} from '@/core/services/funcs';
import {ACT_GET_MY_FARM, ACT_GET_MY_CONNECTS, ACT_GET_COMPARE_FARM, ACT_GET_COMPARE_CONNECTS, GET_WEEKLY_STATISTICS, GET_WEEKLY_COMMENTS} from '@/core/services/variable';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import StatisticsReport from '../../../components/StatisticsReport.vue';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  components: { StatisticsReport },
  name: "WeeklyReport",
  beforeMount() {
		// const { startYmd, endYmd } = this.defaultDateSetting();
    // this.startYmd = startYmd;
		// this.endYmd = endYmd;
		//this.changeFixedDate(7)
    this.onFixedDateRangeChanged(this.fixedDateRange.dateRanges[0].key)
		this.getPageDatas();
  },
  mounted() {
    if(Object.keys(i18n._localeChainCache)[0] == 'en'){
      this.locale = 'en'
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('farm.weeklyReport.farmDataAnalysis'), icon: 'flaticon2-graph' }, { title: i18n.t('farm.weeklyReport.weeklyAnalysis') }]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      myFarm: {},
			farmConnects: [],
			weeklyStatistics: [],
      weeklyComments: [],
      compareFarm: {},
			compareConnects: [],
			weeklyCompareStatistics: [],
      weeklyCompareComments: [],
      startYmd: '',
			endYmd: '',
      compareFno: 10027,
      currentSystemId: 0,
      currentCompareSystemId: 0,
      i18n: {
        startDate: i18n.t('farm.timeseries.startDate'),
        endDate: i18n.t('farm.timeseries.endDate'),
      },
      fixedDateRange: {
        activeDateRange: 'none',
        dateRanges: [
          { key: 'week', date: i18n.t('farm.timeseries.week') },
          { key: 'month', date: i18n.t('farm.timeseries.month') }
        ]
      },
      locale: 'ko',
      firstTime: true,
      compareFirstTime: true,
    }
  },
  methods: {
    setSystemId(val){
      this.currentSystemId = val
    },
    setCompareSystemId(val){
      this.currentCompareSystemId = val
    },
    resetData(){
      this.weeklyStatistics = []
      this.weeklyCompareStatistics = []
    },
    resetMyData(){
      this.weeklyStatistics = []
    },
    resetCompareData(){
      this.weeklyCompareStatistics = []
    },
    getPageDatas(){
      this.resetData()
      Promise.all([
        this.$store.dispatch(ACT_GET_MY_FARM),
        this.$store.dispatch(ACT_GET_MY_CONNECTS),
        this.$store.dispatch(ACT_GET_COMPARE_FARM, this.compareFno),
        this.$store.dispatch(ACT_GET_COMPARE_CONNECTS, this.compareFno),
      ]).then(([myFarm, myConnects, compareFarm, compareConnects]) => {
        if(lengthCheck(myFarm)){
          const myFarmItem = getItem(myFarm)
          this.myFarm = myFarmItem
        }

        if(lengthCheck(myConnects) && this.firstTime){
          const myConnectsItem = getItem(myConnects)
          if(collectionsCheck(myConnectsItem.farmConnects, 'seq')) {
            this.farmConnects = myConnectsItem.farmConnects.filter(connect => connect.internetConnectYn == 'Y');
            this.currentSystemId = this.farmConnects[0].systemId;
          }
          this.firstTime = false
        }

        if(lengthCheck(compareFarm)){
          const compareFarmItem = getItem(compareFarm)
          this.compareFarm = compareFarmItem
        }

        if(lengthCheck(compareConnects) && this.compareFirstTime){
          const compareConnectsItem = getItem(compareConnects)
          if(collectionsCheck(compareConnectsItem.farmConnects, 'seq')) {
            this.compareConnects = compareConnectsItem.farmConnects.filter(connect => connect.internetConnectYn == 'Y');
            this.currentCompareSystemId = this.compareConnects[0].systemId;
            this.compareFirstTime = false
          }
        }

        return Promise.all([
          this.$store.dispatch(GET_WEEKLY_STATISTICS, { fno: this.myFarm.fno, systemId: this.currentSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
          this.$store.dispatch(GET_WEEKLY_STATISTICS, { fno: this.compareFarm.fno, systemId: this.currentCompareSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
          this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.myFarm.fno, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
          this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.compareFarm.fno, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
        ])
      }).then(([weeklyStatistics, weeklyCompareStatistics, weeklyComments, weeklyCompareComments]) => {
        if(lengthCheck(weeklyStatistics)){
          const weeklyStatisticsItems = getItems(weeklyStatistics)
          this.weeklyStatistics = weeklyStatisticsItems
        }

        if(lengthCheck(weeklyCompareStatistics)){
          const weeklyCpmpareStatisticsItems = getItems(weeklyCompareStatistics)
          this.weeklyCompareStatistics = weeklyCpmpareStatisticsItems
        }

        if(lengthCheck(weeklyComments)){
          const weeklyCommentsItems = getItems(weeklyComments)
          this.weeklyComments = weeklyCommentsItems
        }

        if(lengthCheck(weeklyCompareComments)){
          const weeklyCompareCommentsItems = getItems(weeklyCompareComments)
          this.weeklyCompareComments = weeklyCompareCommentsItems
        }
      })

		},
    changeDate(){
      if(this.startYmd && this.endYmd){
        if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.endDateMessage')});
          return;
        }
        const date1 = new Date(this.startYmd)
        const date2 = new Date(this.endYmd)
        const diffDate = date1.getTime() - date2.getTime()
        const dateDays = Math.abs(diffDate / (1000 * 3600 * 24))
        if(dateDays>91){
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.periodDateMessage')});
          return;
        }
				this.fixedDateRange.activeDateRange = 'none'
				this.getPageDatas()
      }
    },
    onFixedDateRangeChanged(dateRange) {
      if (dateRange === this.fixedDateRange.dateRanges[0].key) {
        this.changeFixedDate(7)
      } else if (dateRange === this.fixedDateRange.dateRanges[1].key) {
        this.changeFixedMonth(1)
      }

			this.fixedDateRange.activeDateRange = dateRange
			this.getPageDatas()
    },
    changeFixedDate(day) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)

      const today = todayToDate('yyyy-MM-dd');
      this.endYmd = today
    },
    changeFixedMonth(month) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - month ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)

      const today = todayToDate('yyyy-MM-dd');
      this.endYmd = today
    },
		defaultDateSetting() {
			const today = todayToDate('yyyy-MM-dd');

			// 그래프 시작일을 하루전으로 설정
			let currentDate = new Date();
			const startDate = new Date(currentDate.setDate(currentDate.getDate() - 1 ));
			const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));

			return { startYmd: startDateUTC.toISOString().slice(0, 10), endYmd: today}
		},
    mutateValue(pKey, value){
      // console.log(`pKey : ${pKey},  this[pKey] : ${this[pKey]}`)
      if(this[pKey] !== undefined) this[pKey]= value
    },
    setWeeklyStat(value) {
      this.weeklyStatistics = value
    },
    setComparetWeeklyStat(value) {
      this.weeklyCompareStatistics = value
    },
  }
};
</script>
