<template>
  <div class="col-lg-6 card card-custom card-stretch gutter-b">
    <div class="card-body p-1">

      <b-container v-if="weeklyStatistics">
        <label class="text font-size-h6 font-weight-bold mt-5 mr-5">{{ myFarm.farmNm }} {{i18n.farmStatusAnalysis}}  {{farmType === "comparefarm" ? "[" +i18n.compare+"]": ''}}</label>
        <div class="text font-size-h6 mt-5 mb-5 mr-10 row">
          <span class="mr-5 col-xxl-1">{{i18n.cultivateEnv}}</span>
          <span v-if="weeklyStatisticsTotalPercentageDiagnosis() === 'GOOD'" class="col-xxl-1 d-flex align-items-center flex-column">
            <b-icon icon="emoji-smile" font-scale="4"></b-icon>
            <div>Good</div>
          </span>
          <span v-if="weeklyStatisticsTotalPercentageDiagnosis() === 'NOT_BAD'" class="col-xxl-1 d-flex align-items-center flex-column">
            <b-icon icon="emoji-neutral" font-scale="4"></b-icon>
            <div>Not Bad</div>
          </span>
          <span v-if="weeklyStatisticsTotalPercentageDiagnosis() === 'BAD'" class="col-xxl-1 d-flex align-items-center flex-column">
            <b-icon icon="emoji-frown" font-scale="4"></b-icon>
            <div>Bad</div>
          </span>
          <span v-if="weeklyStatisticsTotalPercentageDiagnosis() === 'NO_DATA'" class="col-xxl-1 d-flex align-items-center flex-column">
            <b-icon icon="emoji-frown" font-scale="4"></b-icon>
            <div>No Data</div>
          </span>

			<span class="border rounded p-5 mr-auto ml-5 align-text-top d-flex col-xxl-6">
				<span>
					{{i18n.diagnosticStandard}}: [~ 20% Good], [20 ~ 40% Not Bad], [40% ~ Bad] [or No Data]
					<br><sub>[{{i18n.totalWeight}}] {{i18n.temperature}}: 0.4/ EC,pH: 0.2/ {{i18n.humidity}},CO2: 0.1</sub>
				</span>
			</span>

			<button class="border rounded p-5 mr-auto ml-5 btn btn-white text-wrap d-flex col-xxl-3" @click="showThreshold()">
				<span class='icon-xl fas fa-info-circle mr-2'></span>
				<span>{{i18n.thresholdcheck}}</span>
			</button>
        </div>
        <div class="controller-tabs">
          <b-tabs nav-class="">
						<b-tab v-for="(connect, i) in farmConnects" :key="i" :active="i === currentSystemId" @click="changeConnect(i)">
              <template slot="title">
								<span class="nav-text font-weight-bolder font-size-h6">{{ connect.connectNm }}</span>
              </template>
            </b-tab>
						<div class="mt-5 mb-5">
							<statistics-report-Chart
								:chartDatas="chartDatas"
								:fno="this.myFarm.fno"
								:system-id="systemId"
								:startYmd="startYmd"
								:endYmd="endYmd"
								:mutate-value="mutateValue"
							/>
						</div>
						<label class="text font-size-h6 font-weight-bold">{{i18n.OutOfRangeNumbersPerDay}}</label>
						<v-data-table v-if="!isMobile"
              :headers="tableHeaders"
              :items="tableItems"
              :items-per-page="7"
              class="elevation-1 farm-data-table"
              :no-data-text="`${i18n.noData}`"
            />
          </b-tabs>
        </div>
      </b-container>
			<b-container v-if="myFarm">
        <label class="text font-size-h6 font-weight-bold mr-5">{{i18n.farmInformation}}</label>
        <b-row>
          <b-col sm>
            <div class='mb-3'>{{i18n.farmArea}} : {{ getValueWithUnit(myFarm.farmArea, '㎡', true)}} </div>
            <div class='mb-3'>{{i18n.farmCultivateArea}} : {{ getValueWithUnit(myFarm.farmCultivateArea, '㎡', true) }}</div>
            <div class='mb-3'>{{i18n.envCtrlCount}} : {{ getValueWithUnit(myFarm.envCtrlCount, `${i18n.units}`, true) }}</div>
            <div class='mb-3'>{{i18n.nutCtrlCount}} : {{ getValueWithUnit(myFarm.nutCtrlCount, `${i18n.units}`, true) }}</div>
          </b-col>
          <b-col sm>
            <div class='mb-3'>{{i18n.farmAvgProductionPDay}} : {{ getValueWithUnit(myFarm.farmAvgProductionPDay, 'kg', true) }}</div>
            <div class='mb-3'>{{i18n.waterAvgUsage}} : {{ getValueWithUnit(myFarm.waterAvgUsage, 'kg', true) }}</div>
            <div class='mb-3'>{{i18n.electricAvgUsage}} : {{ getValueWithUnit(myFarm.electricAvgUsage, `${i18n.won}`, true) }}</div>
            <div class='mb-3'>{{i18n.co2AvgUsage}} : {{ getValueWithUnit(myFarm.co2AvgUsage, `${i18n.won}`, true) }}</div>
          </b-col>
          <b-col>
            <div class='mb-3'>{{i18n.layerCount}} : {{ getValueWithUnit(myFarm.layerCount, `${i18n.units}`, true) }}</div>
            <div class='mb-3'>{{i18n.rackCount}} : {{ getValueWithUnit(myFarm.rackCount, `${i18n.units}`, true) }}</div>
            <div class='mb-3'>{{i18n.bedCount}} : {{ getValueWithUnit(myFarm.bedCount, `${i18n.units}`, true)}}</div>
            <div class='mb-3'>{{i18n.potCount}} : {{ getValueWithUnit(myFarm.potCount, `${i18n.units}`, true) }}</div></b-col>
        </b-row>
      </b-container>

			<b-container v-if="myFarm">
				<div class="text font-size-h6 mt-5 mr-5">
          <label class="text font-size-h6 font-weight-bold">{{i18n.farmCultivationMangementLog}}</label>
					<template v-for="(item, i) in weeklyComments">
						<div class="d-flex align-items-center mb-4" v-bind:key="i">
							{{ getSystemDate(item.systemYmd) }} : {{ item.comment }}
							<span v-if="farmType === 'myfarm'" class="fas fa-trash font-size-sm ml-2 farm-cursor-point " @click="deleteComment(item.seq)"> </span>
						</div>
					</template>

					<div v-if="farmType === 'myfarm'">
						<div class="cw-85">
							<div class="pr-14 cw mt-2">
								<textarea
								v-model="comment"
								:placeholder="`${i18n.cultivationMangementComment}`"
								rows="5"
								max-rows="10"
								wrap="soft"
								class="farm-form-control form-control" >
								</textarea>
							</div>
						</div>
						<div class="card-toolbar toolbar-symbol"><button type="button"  @click="saveComment" class="btn rounded-sm btn-dark btn-sm float-right mr-15 mt-2">{{i18n.register}}</button></div>
					</div>
				</div>
			</b-container>

			<!-- begin:thresholdModal -->
			<b-modal
			v-model="thresholdModal"
			size="xxl"
			hide-footer
			hide-header
			>
			<div class="card card-custom card-stretch">
			<!--begin::Header-->
			<div class="card-header border-0 align-items-center">
				<h3 class="card-title align-items-start flex-column">
				<span class="title font-weight-bold">{{i18n.thresholdInfo}}</span>
				</h3>
			</div>
			<!--end::Header-->
			<!-- begin::Body -->
			<div class="card-body pt-5 overflow-auto">
				<table v-for="(cropState,i) in cropStates" :key="i" class="table table-vertical-center dtr-inline text-center table-condensed table-fixed">
					<colgroup>
					<col style="width:100px;" />
					<col style="width:100px;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					<col style="width:auto;" />
					</colgroup>
					<thead class="thead-light">
					<tr role="row">
					<th rowspan="2" colspan="2" class="rounded"><h4><i :class="`fas ${thresholdIcon[i]} pr-2 text-dark`"></i>{{cropState}}</h4></th>
					<th rowspan="2" colspan="1">{{i18n.targetValue}}</th>
					<th rowspan="1" colspan="2">{{i18n.management}}</th>
					<th rowspan="1" colspan="2">{{i18n.abnormalAlarm}}</th>
					<th rowspan="1" colspan="2">{{i18n.physiologicalDisorder}}</th>
					</tr>
					<tr role="row">
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					<th rowspan="1" colspan="1">{{i18n.maximum}}</th>
					<th rowspan="1" colspan="1">{{i18n.minimum}}</th>
					</tr>
					</thead>
					<tbody v-for="(threshold,j) in 5" :key="j">
					<tr v-if="j<3" role="row">
						<td rowspan="2" colspan="1" class="font-weight-bold">{{thresholdHeadItems[j]}}</td>
						<td rowspan="1" colspan="1" class="font-weight-bold">{{i18n.day}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{j==0 ? baselineItems.tempDay[baselineCropStates[i]][k]: j==1 ? baselineItems.humDay[baselineCropStates[i]][k] : baselineItems.CO2Day[baselineCropStates[i]][k]}}</span>
						</td>
					</tr>
					<tr v-if="j<3" role="row">
						<td rowspan="1" colspan="1" class="font-weight-bold">{{i18n.night}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{j==0 ? baselineItems.tempNight[baselineCropStates[i]][k]: j==1 ? baselineItems.humNight[baselineCropStates[i]][k] : baselineItems.CO2Night[baselineCropStates[i]][k]}}</span>
						</td>
					</tr>
					<tr v-if="j>=3" role="row">
						<td rowspan="1" colspan="2" class="font-weight-bold">{{thresholdHeadItems[j]}}</td>
						<td v-for="(baselineVal,k) in 7" :key=k rowspan="1" colspan="1">
						<span>{{(j==3 ? baselineItems.EC[baselineCropStates[i]][k] : baselineItems.PH[baselineCropStates[i]][k])}}</span>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<!-- end::Body -->
			<!--begin::Footer-->
				<div class="card-footer">
					<b-button variant="secondary" size="lg" class="ml-3 float-right" @click="thresholdModal = false"><span>{{i18n.close}}</span></b-button>
				</div>
				<!--end::Footer-->
			</div>
			</b-modal>
			<!-- end::thresholdModal -->

    </div>
  </div>
</template>

<script>
import {
	getItems,
	lengthCheck,
	dateFormat,
	getResult,
  isSuccess,
	getValueWithUnit
} from '@/core/services/funcs';
import {GET_WEEKLY_STATISTICS, GET_WEEKLY_COMMENTS, ACT_INSERT_MY_WEEKLY_COMMENT, ACT_DELETE_MY_WEEKLY_COMMENT, ACT_GET_FARM_THRESHOLD} from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {syncObj} from '@/core/services/funcs';
import {mapGetters} from 'vuex';
import StatisticsReportChart from '@/components/StatisticsReportChart.vue'
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'StatisticsReport',
  components: {
		StatisticsReportChart
  },
  props: {
		myFarm: Object,
		farmConnects: Array,
		systemId: Number,
		weeklyStatistics: Array,
		weeklyComments: Array,
    startYmd: String,
    endYmd: String,
		farmType: String,
    mutateValue: Function,
		setSystemId: Function,
		setCompareSystemId: Function,
		resetCompareData: Function,
		resetMyData: Function
  },
  computed: {
		...mapGetters(["isMobile"]),
		tableItems() {
			let tableItems = this.weeklyStatistics.map(stat => {
				const { systemYmd, dataCount, tempCount, humidityCount, co2Count, ecCount, phCount, totalPer } = stat;
				const diagnosis = this.totalPercentageDiagnosis(totalPer)

				let totalPerConverted;
				if (diagnosis === 'GOOD') {
					totalPerConverted = 'Good'
				} else if (diagnosis === 'NOT_BAD') {
					totalPerConverted = 'Not Bad'
				} else {
					totalPerConverted = 'Bad'
				}

				return {
					systemYmd: dateFormat(systemYmd),
					dataCount,
					tempCount,
					humidityCount,
					co2Count,
					ecCount,
					phCount,
					totalPer: totalPerConverted
				}
			})

			return tableItems
		},
		chartDatas() {
			const lineChart = this.weeklyStatistics.reduceRight((acc, cur) => {
				acc.dataCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.dataPer])
				acc.tempCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.tempPer])
				acc.humidityCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.humidityPer])
				acc.co2Count.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.co2Per])
				acc.ecCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.ecPer])
				acc.phCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.phPer])
				acc.totalCount.value.push([new Date(dateFormat(cur.systemYmd)).valueOf(), cur.totalPer])
				return acc
			}, {
				dataCount: {
					title: i18n.t('farm.weeklyReport.data'),
					color: 'rgb(80,80,80)',
					chartType: 'line',
					value: []
				},
				tempCount: {
					title: i18n.t('farm.weeklyReport.temperature2'),
					color: 'rgb(255,000,102)',
					chartType: 'line',
					value: []
				},
				humidityCount: {
					title: i18n.t('farm.weeklyReport.humidity2'),
					color: 'rgb(153,255,153)',
					chartType: 'line',
					value: []
				},
				co2Count: {
					title: 'Co2(%)',
					color: 'rgb(189,189,189)',
					chartType: 'line',
					value: []
				},
				ecCount: {
					title: 'EC(%)',
					color: 'rgb(255,51,153)',
					chartType: 'line',
					value: []
				},
				phCount: {
					title: 'pH(%)',
					color: 'rgb(153,102,255)',
					chartType: 'line',
					value: []
				},
				totalCount: {
					title: 'TOTAL(%)',
					color: 'rgb(255,204,051)',
					chartType: 'line',
					value: []
				},
			})

			return lineChart
		},
		getCurrentSystemId(){
			return this.farmConnects[0].systemId;
		}
  },
	watch: {
		currentSystemId(){
			if(this.farmType === 'myfarm'){
				this.setSystemId(this.currentSystemId)
			}else{
				this.setCompareSystemId(this.currentSystemId)
			}
		}
	},
  data() {
    return {
      currentSystemId: this.getCurrentSystemId,
			// currentSystemId: this.farmConnects[0].systemid,
			// weeklyStatistics: [],
			comment: '',
      tableHeaders: [
        { text: i18n.t('farm.weeklyReport.date'), value: 'systemYmd' },
        { text: i18n.t('farm.weeklyReport.dataNumber'), value: 'dataCount' },
        { text: i18n.t('farm.weeklyReport.temperature'), value: 'tempCount' },
        { text: i18n.t('farm.weeklyReport.humidity'), value: 'humidityCount' },
        { text: 'co2', value: 'co2Count' },
        { text: 'EC', value: 'ecCount' },
		{ text: 'pH', value: 'phCount' },
		{ text: i18n.t('farm.weeklyReport.farmStatus'), value: 'totalPer' },
      ],
			loaded: false,
      i18n:{
        add: i18n.t('claim.registerClaim.add'),
		farmStatusAnalysis: i18n.t('farm.weeklyReport.farmStatusAnalysis'),
		cultivateEnv: i18n.t('farm.weeklyReport.cultivateEnv'),
		cultivationMangementCommentDelete: i18n.t('farm.weeklyReport.cultivationMangementCommentDelete'),
		error: i18n.t('farm.weeklyReport.error'),
		cultivationMangementCommentAsk: i18n.t('farm.weeklyReport.cultivationMangementCommentAsk'),
		cultivationMangementCommentAdded: i18n.t('farm.weeklyReport.cultivationMangementCommentAdded'),
		selectController: i18n.t('farm.weeklyReport.selectController'),
		typecultivationMangementComment: i18n.t('farm.weeklyReport.typecultivationMangementComment'),
		diagnosticStandard: i18n.t('farm.weeklyReport.diagnosticStandard'),
		weight: i18n.t('farm.weeklyReport.weight'),
		farmEnvOutOfRangeStatus: i18n.t('farm.weeklyReport.farmEnvOutOfRangeStatus'),
		temperature: i18n.t('farm.weeklyReport.temperature'),
		humidity: i18n.t('farm.weeklyReport.humidity'),
		OutOfRangeNumbersPerDay: i18n.t('farm.weeklyReport.OutOfRangeNumbersPerDay'),
		noData: i18n.t('farm.weeklyReport.noData'),
		farmInformation: i18n.t('farm.weeklyReport.farmInformation'),
		farmArea: i18n.t('farm.weeklyReport.farmArea'),
		farmCultivateArea: i18n.t('farm.weeklyReport.farmCultivateArea'),
		envCtrlCount: i18n.t('farm.weeklyReport.envCtrlCount'),
		nutCtrlCount: i18n.t('farm.weeklyReport.nutCtrlCount'),
		farmAvgProductionPDay: i18n.t('farm.weeklyReport.farmAvgProductionPDay'),
		waterAvgUsage: i18n.t('farm.weeklyReport.waterAvgUsage'),
		electricAvgUsage: i18n.t('farm.weeklyReport.electricAvgUsage'),
		co2AvgUsage: i18n.t('farm.weeklyReport.co2AvgUsage'),
		layerCount: i18n.t('farm.weeklyReport.layerCount'),
		rackCount: i18n.t('farm.weeklyReport.rackCount'),
		potCount: i18n.t('farm.weeklyReport.potCount'),
		farmCultivationMangementLog: i18n.t('farm.weeklyReport.farmCultivationMangementLog'),
		cultivationMangementComment: i18n.t('farm.weeklyReport.cultivationMangementComment'),
		units: i18n.t('farm.weeklyReport.units'),
		standard: i18n.t('farm.weeklyReport.standard'),
		bedCount: i18n.t('farm.weeklyReport.bedCount'),
		won: i18n.t('farm.weeklyReport.won'),
		yield: i18n.t('farm.weeklyReport.yield'),
		data: i18n.t('farm.weeklyReport.data'),
		temperature2: i18n.t('farm.weeklyReport.temperature2'),
		humidity2: i18n.t('farm.weeklyReport.humidity2'),
		register: i18n.t('farm.weeklyReport.register'),
		compare: i18n.t('farm.weeklyReport.compare'),
		totalWeight: i18n.t('farm.weeklyReport.totalWeight'),
		thresholdcheck: i18n.t('farm.weeklyReport.thresholdcheck'),
		thresholdInfo: i18n.t('farm.weeklyReport.threshold.thresholdInfo'),
		targetValue:i18n.t('farm.weeklyReport.threshold.targetValue'),
		management: i18n.t('farm.weeklyReport.threshold.management'),
		abnormalAlarm: i18n.t('farm.weeklyReport.threshold.abnormalAlarm'),
		physiologicalDisorder: i18n.t('farm.weeklyReport.threshold.physiologicalDisorder'),
		maximum: i18n.t('farm.weeklyReport.threshold.maximum'),
		minimum: i18n.t('farm.weeklyReport.threshold.minimum'),
		day: i18n.t('farm.weeklyReport.threshold.day'),
		night: i18n.t('farm.weeklyReport.threshold.night'),
		close: i18n.t('farm.weeklyReport.threshold.close'),
      },
	  thresholdModal: false,
	  thresholdIcon: ['fa-spa','fa-seedling', 'fa-leaf'],
	  thresholdHeadItems: [i18n.t('farm.weeklyReport.threshold.temp'),i18n.t('farm.weeklyReport.threshold.hum'),'CO2 (ppm)', 'EC (dS/m)', 'pH'],
	  baselineCropStates: ['seedling','transplant','lastfeed'],
	  cropStates: [i18n.t('farm.weeklyReport.threshold.seedling'), i18n.t('farm.weeklyReport.threshold.transplant'), i18n.t('farm.weeklyReport.threshold.lastfeed')],
	  baselineItems:{
			tempDay: {seedling:[22,23,21,25,19,27,10], transplant:[22,23,21,25,19,27,10], lastfeed:[22,23,21,25,19,27,10]},
			tempNight: {seedling:[18,20,17,22,15,27,10], transplant:[18,20,17,22,15,27,10],lastfeed:[18,20,17,22,15,27,10]},
			humDay: {seedling:[70,80,60,100,50,100,20], transplant:[70,80,60,100,50,100,20], lastfeed:[70,80,60,100,50,100,20]},
			humNight: {seedling:[80,90,70,100,50,100,20], transplant:[80,90,70,100,50,100,20], lastfeed:[80,90,70,100,50,100,20]},
			CO2Day:{seedling:[900,1000,800,1200,600,'-','-'], transplant:[900,1000,800,1200,600,'-','-'], lastfeed:[900,1000,800,1200,500,'-','-']},
			CO2Night:{seedling:[900,1000,800,1200,600,'-','-'], transplant:[900,1000,800,1200,600,'-','-'], lastfeed:[900,1000,800,1200,500,'-','-']},
			EC: {seedling:[1.2,1.7,1.5,1.8,1.4,4.0,0.8], transplant:[1.7,1.9,1.7,2.0,1.6,4.0,0.8], lastfeed:[2.3,2.5,2.0,2.7,1.8,4.0,0.8]},
			PH: {seedling:[6.1,6.2,5.8,6.5,5.5,7.5,5.0], transplant:[6.1,6.2,5.8,6.5,5.5,7.5,5.0], lastfeed:[6,6.2,5.8,6.5,5.5,7.5,5.0]},
		},
		seedEcMax: 1.70,
		seedEcMin: 1.50,
		seedEcLimithigh: 1.80,
		seedEcLimitlow: 1.40,
		seedEcUrgenthigh: 4.00,
		seedEcUrgentlow: 0.80,
		transplantEcMax: 1.90,
		transplantEcMin: 1.70,
		transplantEcLimithigh: 2.00,
		transplantEcLimitlow: 1.60,
		transplantEcUrgenthigh: 4.00,
		transplantEcUrgentlow: 0.80,
		ecMax: 2.50,
		ecMin: 2.00,
		ecLimithigh: 2.70,
		ecLimitlow: 1.80,
		ecUrgenthigh: 4.00,
		ecUrgentlow: 0.80,
		phMax: 6.20,
		phMin: 5.80,
		phLimithigh: 6.50,
		phLimitlow: 5.50,
		phUrgenthigh: 7.50,
		phUrgentlow: 5.00,
		co2DayMax: 1000,
		co2DayMin: 800,
		co2DayLimithigh: 1200,
		co2DayLimitlow: 500,
		co2DayUrgenthigh: 0,
		co2DayUrgentlow: 0,
		co2NightMax: 1000,
		co2NightMin: 800,
		co2NightLimithigh: 1200,
		co2NightLimitlow: 500,
		co2NightUrgenthigh: 0,
		co2NightUrgentlow: 0,
		tempDayMax: 23,
		tempDayMin: 21,
		tempDayLimithigh: 25,
		tempDayLimitlow: 19,
		tempDayUrgenthigh: 27,
		tempDayUrgentlow: 10,
		tempNightMax: 20,
		tempNightMin: 17,
		tempNightLimithigh: 22,
		tempNightLimitlow: 15,
		tempNightUrgenthigh: 27,
		tempNightUrgentlow: 10,
		humidityDayMax: 80,
		humidityDayMin: 60,
		humidityDayLimithigh: 100,
		humidityDayLimitlow: 50,
		humidityDayUrgenthigh: 100,
		humidityDayUrgentlow: 20,
		humidityNightMax: 90,
		humidityNightMin: 70,
		humidityNightLimithigh: 100,
		humidityNightLimitlow: 50,
		humidityNightUrgenthigh: 100,
		humidityNightUrgentlow: 20,
		waterTempMax: 0,
		waterTempMin: 0,
		waterTempLimithigh: 0,
		waterTempLimitlow: 0,
		waterTempUrgenthigh: 0,
		waterTempUrgentlow: 0,
	}
  },
  methods: {
		showThreshold(){
			this.thresholdModal = true

			this.$store.dispatch(ACT_GET_FARM_THRESHOLD, this.myFarm.fno).then(resp =>{
				if(!(resp.items.length == 0)){
					syncObj(this, resp.items[0])
					this.setBaseline()
				}else{
					this.$store.dispatch(ACT_GET_FARM_THRESHOLD, 0).then(baselineResp =>{
					let baselineItems = getItem(baselineResp)
					syncObj(this, baselineItems)
					this.setBaseline()
					})
				}
			})
		},
		setBaseline(){
			this.baselineItems = {
				tempDay: {seedling:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh],
								transplant:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh],
								lastfeed:[(this.tempDayMax+this.tempDayMin)/2,this.tempDayMin,this.tempDayMax,this.tempDayLimitlow,this.tempDayLimithigh,this.tempDayUrgentlow,this.tempDayUrgenthigh]},
				tempNight: {seedling:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh],
										transplant:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh],
										lastfeed:[(this.tempNightMax+this.tempNightMin)/2,this.tempNightMin,this.tempNightMax,this.tempNightLimitlow,this.tempNightLimithigh,this.tempNightUrgentlow,this.tempNightUrgenthigh]},
				humDay: {seedling:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh],
								transplant:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh],
								lastfeed:[(this.humidityDayMax+this.humidityDayMin)/2,this.humidityDayMin,this.humidityDayMax,this.humidityDayLimitlow,this.humidityDayLimithigh,this.humidityDayUrgentlow,this.humidityDayUrgenthigh]},
				humNight: {seedling:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh],
								transplant:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh],
								lastfeed:[(this.humidityNightMax+this.humidityNightMin)/2,this.humidityNightMin,this.humidityNightMax,this.humidityNightLimitlow,this.humidityNightLimithigh,this.humidityNightUrgentlow,this.humidityNightUrgenthigh]},
				CO2Day:{seedling:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh],
						transplant:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh],
						lastfeed:[(this.co2DayMax+this.co2DayMin)/2,this.co2DayMin,this.co2DayMax,this.co2DayLimitlow,this.co2DayLimithigh,this.co2DayUrgentlow,this.co2DayUrgenthigh]},
				CO2Night:{seedling:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh],
						transplant:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh],
						lastfeed:[(this.co2NightMax+this.co2NightMin)/2,this.co2NightMin,this.co2NightMax,this.co2NightLimitlow,this.co2NightLimithigh,this.co2NightUrgentlow,this.co2NightUrgenthigh]},
				EC: {seedling:[(this.seedEcMax+this.seedEcMin)/2,this.seedEcMin,this.seedEcMax,this.seedEcLimitlow,this.seedEcLimithigh,this.seedEcUrgentlow,this.seedEcUrgenthigh],
						transplant:[((this.transplantEcMax+this.transplantEcMin)/2).toFixed(1),this.transplantEcMin,this.transplantEcMax,this.transplantEcLimitlow,this.transplantEcLimithigh,this.transplantEcUrgentlow,this.transplantEcUrgenthigh],
						lastfeed:[(this.ecMax+this.ecMin)/2,this.ecMin,this.ecMax,this.ecLimitlow,this.ecLimithigh,this.ecUrgentlow,this.ecUrgenthigh]},
				PH: {seedling:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh],
						transplant:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh],
						lastfeed:[(this.phMax+this.phMin)/2,this.phMin,this.phMax,this.phLimitlow,this.phLimithigh,this.phUrgentlow,this.phUrgenthigh]},
			}
		},
		weeklyStatisticsTotalPercentageDiagnosis() {
			const accumulatedTotalPer = this.weeklyStatistics.reduce((acc, cur) => {
				acc += cur.totalPer
				return acc
			}, 0);

			return this.totalPercentageDiagnosis(accumulatedTotalPer/this.weeklyStatistics.length)
		},
		totalPercentageDiagnosis(totalPercentage) {
			if (totalPercentage <= 20) {
				return 'GOOD';
			} else if (totalPercentage <= 40) {
				return 'NOT_BAD';
			} else if (totalPercentage > 40){
				return 'BAD';
			} else {
				return 'NO_DATA'
			}
		},
		changeConnect(index) {
			if(this.farmType === 'myfarm'){
				this.resetMyData()
			}else{
				this.resetCompareData()
			}
		if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.endDateMessage')});
          return;
        }
        const date1 = new Date(this.startYmd)
        const date2 = new Date(this.endYmd)
        const diffDate = date1.getTime() - date2.getTime()
        const dateDays = Math.abs(diffDate / (1000 * 3600 * 24))
        if(dateDays>91){
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.periodDateMessage')});
          return;
        }
      this.currentSystemId = this.farmConnects[index].systemId;
			Promise.all([
        this.$store.dispatch(GET_WEEKLY_STATISTICS, { fno: this.myFarm.fno, systemId: this.currentSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
        this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.myFarm.fno, systemId: this.currentSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
			]).then(([weeklyStatistics, weeklyComments]) => {
        if(lengthCheck(weeklyStatistics)){
          const weeklyStatisticsItems = getItems(weeklyStatistics)
					// this.weeklyStatistics = weeklyStatisticsItems
          if (this.farmType === "comparefarm"){
            // this.$emit('seComparetWeeklyStat', weeklyStatisticsItems)
            this.mutateValue('weeklyCompareStatistics', weeklyStatisticsItems);
          } else {
            // this.$emit('setWeeklyStat', weeklyStatisticsItems)
            this.mutateValue('weeklyStatistics', weeklyStatisticsItems);
          }
				}

				if(lengthCheck(weeklyComments)){
          const weeklyCommentsItems = getItems(weeklyComments)
          if (this.farmType === "comparefarm"){
            this.mutateValue('weeklyCompareComments', weeklyCommentsItems);
          } else {
            this.mutateValue('weeklyComments', weeklyCommentsItems);
          }
				}
			})
		},
		saveComment(){
			this.currentSystemId = this.getCurrentSystemId

      // if(this.loaded) return;
      if(this.validateParams()){
        this.loaded = true
        const params = {
					systemId: this.currentSystemId,
          comment: this.comment,
        }
				this.insertComment(params);
      }
    },
		deleteComment(seq){
			const params = {
				seq: seq,
			}
      const yesCallback = () => {
        this.$store.dispatch(ACT_DELETE_MY_WEEKLY_COMMENT, params)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.cultivationMangementCommentDelete'), color: 'success'});
						Promise.all([
							this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.myFarm.fno, systemId: this.currentSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
						]).then(([weeklyComments]) => {
							if(lengthCheck(weeklyComments)){
								const weeklyCommentsItems = getItems(weeklyComments)
								this.mutateValue('weeklyComments', weeklyCommentsItems);
							} else {
								this.mutateValue('weeklyComments', null);
							}
						})
					}
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.error')});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('farm.weeklyReport.cultivationMangementCommentAsk')},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });

    },
    insertComment(params){
      const yesCallback = () => {
        this.$store.dispatch(ACT_INSERT_MY_WEEKLY_COMMENT, params)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.cultivationMangementCommentAdded'), color: 'success'});

						Promise.all([
							this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.myFarm.fno, systemId: this.currentSystemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
						]).then(([weeklyComments]) => {
							if(lengthCheck(weeklyComments)){
								const weeklyCommentsItems = getItems(weeklyComments)
								if (this.farmType === "comparefarm"){
									this.mutateValue('weeklyCompareComments', weeklyCommentsItems);
								} else {
									this.mutateValue('weeklyComments', weeklyCommentsItems);
								}
							}
						})
						this.comment = '';
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.loaded = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.weeklyReport.error')});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('farm.weeklyReport.cultivationMangementCommentAddAsk')},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });
    },
		validateParams(){
      let result = false;
      let message = ''
      if(!this.currentSystemId){
        message = i18n.t('farm.weeklyReport.selectController');
      }else if(!this.comment){
        message = i18n.t('farm.weeklyReport.typecultivationMangementComment');
      }else {
        result = true;
      }

      if(!result){
        this.$store.dispatch(ACT_ADD_ALERT, {message: message});
      }
      return result;
    },
		getSystemDate(systemYmd) {
			return dateFormat(systemYmd)
		},
		getValueWithUnit
  }
};
</script>