<template>
  <div style="height: 100%; min-height: 230px">
    <highcharts v-if="chartDatas" :options="chartOptions" v-bind:style="{ height: '100%'}"></highcharts>
    <div v-else class="text-center" v-bind:style="{ height: '100%'}">
      <span v-if="loading" class="spinner farm-chart-spinner" style="top: 70px;"></span>
      <span v-else style="top: 70px;"><br><br><br>No Data</span>
    </div>
    <b-modal v-model="commentModal"
          title="Comment"
          size="lg"
          centered
          hide-footer
    >
      <div class="card-body">
        <!-- begin::major-content -->
        <div class="major-content form-section">
          <!-- begin::form-group -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.systemId}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="systemId" type="text" class="form-control" disabled>
            </div>
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.collectDate}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="systemDate" type="text" class="form-control" disabled>
            </div>
          </div>
          <!-- begin::form-group -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label ">
              <span class="h5 font-weight-bold">Comment</span>
            </label>
            <div class="input-group col-sm-10">
              <div class="input-group">
                <textarea v-model="comment" class="form-control" rows="4"></textarea>
              </div>
            </div>
          </div>
          <!-- end::form-group -->
        </div>
        <!-- end::major-content -->
      </div>
      <!-- end::card-body -->
      <!-- begin::card-footer -->
      <div class="card-footer p-0 pt-5">
        <div class="d-flex align-items-center justify-content-end">
          <div class="button-group">
            <button type="button" class="btn btn-lg btn-secondary mr-2" @click="commentModal = false">{{i18n.cancel}}</button>
            <button type="button" class="btn btn-lg btn-dark" @click="saveComment()">{{i18n.register}}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import {
  getRefinedHighchartSeriesData,
  timestampToDate,
  lengthCheck,
  getItems,
	getResult,
  isSuccess,
} from '@/core/services/funcs.js';
import {GET_WEEKLY_COMMENTS, ACT_INSERT_MY_WEEKLY_COMMENT} from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'StatisticsReportChart',
  components: {
    highcharts: Chart
  },
  props: {
		chartDatas: Object,
    fno: Number,
    systemId: Number,
    startYmd: String,
    endYmd: String,
    mutateValue: Function,
  },
  computed: {
    chartOptions(){
      const options = {
        chart: {
          zoomType: 'x,y',
          backgroundColor: '#F3F6F9'
        },
        plotOptions: {
          series: {
            turboThreshold: 10000
          }
        },
        title: {
          text: '<span class="font-weight-bold"></span>'
        },
        yAxis: {
					title: {
						text: '%'
					}
				},
        xAxis: {
          type: 'datetime',
          title: '일자',
          labels: {
            format: '{value:%y.%m.%d}',
          },
          // 이거 코드 사용시 xAxis하고 데이터하고 중심이 잘 맞지만 datetime이 안보여 버림
          // 날짜가 보이는게 우선이므로 일단은 보류

          // tickPositioner: function() {
          //   const chart = this.chart,
          //     tickPositions = [];

          //   chart.series[0].data.forEach((point) => {
          //     tickPositions.push(point.x)
          //   });

          //   this.update({
          //     tickPositions: tickPositions,
          //   });
          // }
        },
        credits: {
          enabled: false
        },
        tooltip: {
					valueDecimals: 1,
					shared: true
				},
        series:[]
			};

			options.yAxis.min =  0
			options.yAxis.max =  200

      Object.values(this.chartDatas).map(data => {
        const refinedSeriesData = getRefinedHighchartSeriesData(data.value)
        const series = {
          name: data.title,
					type: data.chartType,
          color: data.color,
					data: refinedSeriesData,
          events: {
            click: (event) => {
              if (this.fno != 10027){
                let submitDate = new Date(event.point.options.x)
                submitDate.setHours(submitDate.getHours() - 9)
                this.viewComment(timestampToDate(submitDate.getTime(), 'yyyy.MM.dd hh:mm'), event.point.options.y)
              }
            }
          }
				};

        options.series.push(series)
			})

      return options;
    }
  },
  data (){
    return {
      commentModal: false,
      systemDate: '',
      comment: '',
      elementValue: 0,

      i18n:{
        register: i18n.t('farm.weeklyReport.register'),
        systemId: i18n.t('farm.weeklyReport.systemId'),
        collectDate: i18n.t('farm.weeklyReport.collectDate'),
        cancel: i18n.t('farm.weeklyReport.cancel'),
        commentEditted: i18n.t('farm.weeklyReport.commentEditted'),
        commentConfirm: i18n.t('farm.weeklyReport.commentConfirm'),
        connectNotSelected: i18n.t('farm.weeklyReport.connectNotSelected'),
        commentplz: i18n.t('farm.weeklyReport.commentplz'),
        error: i18n.t('farm.weeklyReport.error'),
      },
    }
  },
  methods: {
    viewComment(dateTime, yValue) {
      this.comment = ''
      this.systemDate = dateTime
      this.elementValue = yValue
      this.commentModal = true
    },
    saveComment(){
      if(this.validateParams()){
        // this.commentModal = true

        const params = {
					systemId: this.systemId,
          comment: this.comment,
          systemYmd: this.systemDate.replace(/./g, '')
        }

				this.insertComment(params);
      }
    },
    insertComment(params){
      const yesCallback = () => {
        this.$store.dispatch(ACT_INSERT_MY_WEEKLY_COMMENT, params)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.commentEditted, color: 'success'});

						Promise.all([
							this.$store.dispatch(GET_WEEKLY_COMMENTS, { fno: this.fno, systemId: this.systemId, startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, '') }),
						]).then(([weeklyComments]) => {
							if(lengthCheck(weeklyComments)){
								const weeklyCommentsItems = getItems(weeklyComments)
								if (this.farmType === "comparefarm"){
									this.mutateValue('weeklyCompareComments', weeklyCommentsItems);
								} else {
									this.mutateValue('weeklyComments', weeklyCommentsItems);
								}
							}
              this.commentModal = false
						})
						this.comment = '';
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.commentModal = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.commentModal = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.commentConfirm},
        callback: yesCallback,
        cancelCallback: () => {this.commentModal = false}
      });
    },
		validateParams(){
      let result = false;
      let message = ''
      if(!this.systemId){
        message = this.i18n.connectNotSelected;
      }else if(!this.comment){
        message = this.i18n.commentplz;
      }else {
        result = true;
      }

      if(!result){
        this.$store.dispatch(ACT_ADD_ALERT, {message: message});
      }
      return result;
    },
  }
};
</script>